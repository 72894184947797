import { useState } from 'react';
import { FingerYesIcon, IconColor, SummerIcon, TimerIcon, WinterIcon } from './Icons';
import { Stepper } from './Stepper';
import SwipableCardStack from './SwipableCardStack';
import SwipeDemo from './SwipeDemo';

import './../Styles/Tutorial.scss';
import TemperatureCursor, { DomTomTemperatureCursor } from './TemperatureCursor';

type BaseTutorialProps = {
    readonly onWinterSelected: () => void;
    readonly onSummerSelected: () => void;
};

const BaseTutorial = (props: BaseTutorialProps) => {

    const steps: StepContentProps[] = [
        {
            okButton: <FingerYesIcon color="Green" outlineColor="Yellow" />,
            mainContent: <BaseStep1Content />,
            bottomContent: <BaseStep1BottomContent />
        },
        {
            okButton: <FingerYesIcon color="Green" outlineColor="Yellow" />,
            mainContent: <BaseStep2Content />,
            bottomContent: <BaseStep2BottomContent />
        },
        {
            okButton: <FingerYesIcon color="Green" outlineColor="Yellow" />,
            mainContent: <BaseStep3Content />,
            bottomContent: <BaseStep3BottomContent />
        },
        {
            notOkButton: <WinterIcon color="Green" />,
            notOkLegend: "Hiver",
            notOkLegendColor: "Green",

            okButton: <SummerIcon color="Yellow" />,
            okLegend: "Été",
            okLegendColor: "Yellow",

            mainContent: <BaseStep4Content />,
        },
    ];
    const stepCount = steps.length;

    const [step, setStep] = useState<number>(1);

    const onValidation = (isOk: boolean) => {

        const { onSummerSelected, onWinterSelected } = props;

        if (step < stepCount) {
            setStep(step + 1);
        }
        else {
            if (isOk) {
                onSummerSelected();
            }
            else {
                onWinterSelected();
            }
        }
    }

    return <Tutorial {...{ step, steps, onValidation }} />
}


type DomTomTutorialProps = {
    readonly onReady: () => void;
}

export const DomTomTutorial = (props: DomTomTutorialProps) => {

    const { onReady } = props;

    const steps: StepContentProps[] = [
        {
            okButton: <FingerYesIcon color="Green" outlineColor="Yellow" />,
            mainContent: <DomTomStep1Content />,
            bottomContent: <DomTomStep1BottomContent />
        },
        {
            okButton: <FingerYesIcon color="Green" outlineColor="Yellow" />,
            mainContent: <DomTomStep2Content />,
            bottomContent: <DomTomStep2BottomContent />
        },
        {
            okButton: <FingerYesIcon color="Green" outlineColor="Yellow" />,
            mainContent: <DomTomStep3Content />,
            bottomContent: <DomTomStep3BottomContent />
        },
    ];
    const stepCount = steps.length;

    const [step, setStep] = useState<number>(1);

    const onValidation = (isOk: boolean) => {

        if (step < stepCount) {
            setStep(step + 1);
        }
        else {
            onReady();
        }
    }

    return <Tutorial {...{ step, steps, onValidation }} />
}

interface TutorialProps {
    readonly step: number;
    readonly steps: StepContentProps[];
    readonly onValidation: (isOk: boolean) => void;
}

const Tutorial = (props: TutorialProps) => {
    const {
        step, steps, onValidation,
    } = props;

    const { 
        okButton, okLegend, okLegendColor,
        notOkButton, notOkLegend, notOkLegendColor,
        mainContent: stepContent, bottomContent
    } = steps[step - 1];

    return <div className="tutorial d-flex flex-column w-100 h-100 align-items-center justify-content-around">
        <div className="tutorial-top">
            <Stepper step={step} stepCount={steps.length} />
        </div>
        <div className="w-100 tutorial-main">
            <SwipableCardStack 
                notOkIcon={notOkButton} onNotOk={() => onValidation(false)} notOkEnabled={notOkButton !== undefined}
                notOkLegend={notOkLegend} notOkLegendColor={notOkLegendColor}
                okIcon={okButton} onOk={() => onValidation(true)} okEnabled={okButton !== undefined}
                okLegend={okLegend} okLegendColor={okLegendColor}
            >
                {stepContent}
            </SwipableCardStack>
        </div>
        <div className="tutorial-bottom">
            {bottomContent}
        </div>
    </div>
}


type StepContentProps = {
    readonly notOkButton?: JSX.Element;
    readonly notOkLegend?: string;
    readonly notOkLegendColor?: IconColor;

    readonly okButton?: JSX.Element;
    readonly okLegend?: string;
    readonly okLegendColor?: IconColor;

    readonly mainContent: JSX.Element;
    readonly bottomContent?: JSX.Element;
}

const StepContent = (props: { children: JSX.Element[]|JSX.Element}) => <div
        className="d-flex flex-column justify-content-center align-items-center w-100 h-100 p-4 overflow-auto text-center"
    >
    {props.children}
</div>;

const StepBottomContent = (props: { children: JSX.Element[]|JSX.Element}) => <div
        className="d-flex flex-column justify-content-center align-items-center w-100 h-100"
    >
    {props.children}
</div>;


const BaseStep1Content = () => <StepContent>
	<div>Glisse la carte centrale sur le picto correspondant à ta réponse.</div>
</StepContent>

const BaseStep1BottomContent = () => <StepBottomContent>
	<SwipeDemo />
</StepBottomContent>

const BaseStep2Content = () => <StepContent>
	<div>Cette activité se déroule en deux manches.</div>
    <div>Ta mission : utiliser les principes du bioclimatisme pour optimiser le confort des habitants dans un bâtiment.</div>
</StepContent>

const BaseStep2BottomContent = () => <StepBottomContent>
    <TemperatureCursor percentage={50.0} />
</StepBottomContent>

const BaseStep3Content = () => <StepContent>
	<div>
        Avec une bonne réponse tu te rapprocheras de la température la plus confortable, mais avec une mauvaise réponse tu t'en éloigneras.
    </div>
</StepContent>

const BaseStep3BottomContent = () => <StepBottomContent>
	<div className="w-100 d-flex flex-row align-items-center justify-content-around">
        <TimerIcon color="DarkGreen" />
        <div className="w-75 tuto-step3-text">
            Attention, tu n'as que trois minutes pour terminer chaque manche.
        </div>
    </div>
</StepBottomContent>

const BaseStep4Content = () => <StepContent>
	<div>Choisis ta saison pour commencer.</div>
</StepContent>



const DomTomStep1Content = () => <StepContent>
	<div>Glisse la carte centrale sur le picto correspondant à ta réponse.</div>
</StepContent>

const DomTomStep1BottomContent = () => <StepBottomContent>
	<SwipeDemo />
</StepBottomContent>

const DomTomStep2Content = () => <StepContent>
	<div>Cette activité se déroule en deux manches.</div>
    <div>Ta mission : utiliser les principes du bioclimatisme pour optimiser le confort des habitants dans un bâtiment.</div>
</StepContent>

const DomTomStep2BottomContent = () => <StepBottomContent>
    <DomTomTemperatureCursor percentage={50.0} />
</StepBottomContent>

const DomTomStep3Content = () => <StepContent>
	<div>
        Avec une bonne réponse tu te rapprocheras de la température la plus confortable, mais avec une mauvaise réponse tu t'en éloigneras.
    </div>
</StepContent>

const DomTomStep3BottomContent = () => <StepBottomContent>
	<div className="w-100 d-flex flex-row align-items-center justify-content-around">
        <TimerIcon color="DarkGreen" />
        <div className="w-75 tuto-step3-text">
            Attention, tu n'as que trois minutes pour terminer chaque manche.
        </div>
    </div>
</StepBottomContent>


export default BaseTutorial;