import { GameLevelProps } from "../Game/GameLogic";
import { StartIcon } from "./Icons";

import '../Styles/HomeScreen.scss';

type HomeScreenProps = GameLevelProps;
const HomeScreen = (props: HomeScreenProps) => <div className="homescreen w-100 h-100 d-flex align-items-center justify-content-center">
        <div className="d-flex flex-column h-75 w-50 align-items-center justify-content-evenly">
        <h1 className="text-center">Quels sont les éléments qui interviennent dans le comportement bioclimatique d’un bâtiment ?</h1>
        <div className="icon-btn w-50 mt-4" onClick={e => props.onLevelEnd()}>
            <StartIcon color="Yellow"/>
        </div>
    </div>
</div>;

export default HomeScreen;