import { ContinueIcon } from "./Icons";

import './../Styles/Popup.scss';

type PopupProps = {
    readonly displayed: boolean;
    readonly onContinueBtnClicked: Function;
    readonly children?: JSX.Element|JSX.Element[];
};

const Popup = (props: PopupProps) => {
    const { displayed, onContinueBtnClicked, children } = props;
    
    const PopupClasses = [
        "popup"
    ];

    PopupClasses.push(displayed ? "popup-displayed" : "popup-hidden");

    return <div className={PopupClasses.join(" ")}>
        <div className="popup-background">
            <div className="d-flex flex-column mx-auto vh-100 w-75 align-items-center justify-content-center">
                {children}
                <div className="icon-btn w-100 mt-3" onClick={() => onContinueBtnClicked()}>
                    <ContinueIcon color="Green" />
                </div>
            </div>
        </div>
    </div>;
}

export default Popup;