import { useEffect, useRef, useState } from 'react';
import Questions from '../Game/GameDatabase';
import { Answer, GameLevelProps, getTemperature, LevelStats, TemperatureDirection } from '../Game/GameLogic';
import { shuffled } from '../Services/Helpers';
import Popup from './Popup';
import SwipableCardStack from './SwipableCardStack';
import TemperatureCursor, { DomTomTemperatureCursor, TemperatureCursorProps } from './TemperatureCursor';
import Timer from './Timer';

import './../Styles/Levels.scss';
import { ColdIcon, HotIcon, IconColor } from './Icons';


type LevelQuestion = {
    readonly index: number;
    readonly content: JSX.Element;
    readonly correctAnswer: Answer;
}

type LevelProps = {
    readonly setAnswers: (result: (Answer|undefined)[]) => void;
} & GameLevelProps;

const getLvl1Questions = (): LevelQuestion[] => [...Questions.SummerLevel];
const getLvl2Questions = (): LevelQuestion[] => [...Questions.WinterLevel];
const getDomTomLvl1Questions = (): LevelQuestion[] => [...Questions.DomTomLevel1];
const getDomTomLvl2Questions = (): LevelQuestion[] => [...Questions.DomTomLevel2];


const coolColor: IconColor = "Green";
const coolBtn = <ColdIcon color={coolColor} />;

const warmColor: IconColor = "Yellow";
const warmBtn = <HotIcon color={warmColor} />;

export const SummerLevelScene = (props: LevelProps) => <LevelScene
    title="En été, on cherche à se protéger de la chaleur du soleil."
    originalQuestions={getLvl1Questions()}
    temperatureDirection="HotToCold"
    coolLegend="Augmente le confort"
    warmLegend = "Dégrade le confort"
    swapAnswers={true}
    swapWarmCold={false}
    {...props}
/>


export const WinterLevelScene = (props: LevelProps) => <LevelScene
    title="En hiver, on cherche à capter la chaleur et la lumière du soleil et à se protéger du froid."
    originalQuestions={getLvl2Questions()}
    temperatureDirection="ColdToHot"
    coolLegend="Dégrade le confort"
    warmLegend="Augmente le confort"
    swapAnswers={false}
    swapWarmCold={false}
    {...props}
/>

export const DomTomLevel1Scene = (props: LevelProps) => <LevelScene
    title="En climat tropical, quels éléments permettent de réduire les apports de chaleur dans le bâtiment ?"
    originalQuestions={getDomTomLvl1Questions()}
    temperatureDirection="HotToComfort"
    coolLegend="Augmente le confort"
    warmLegend="Dégrade le confort"
    cursor={DomTomTemperatureCursor}
    swapAnswers={false}
    swapWarmCold={true}
    {...props}
/>

export const DomTomLevel2Scene = (props: LevelProps) => <LevelScene
    title="Nous cherchons maintenant à identifier ce qui est favorable à la ventilation naturelle et au rafraîchissement passif des bâtiments."
    originalQuestions={getDomTomLvl2Questions()}
    temperatureDirection="HotToComfort"
    coolLegend="Augmente le confort"
    warmLegend="Dégrade le confort"
    cursor={DomTomTemperatureCursor}
    swapAnswers={false}
    swapWarmCold={true}
    {...props}
/>



type PrivateLevelProps = {
    readonly title: string;
    readonly originalQuestions: LevelQuestion[];
    readonly warmLegend: string;
    readonly coolLegend: string;

    readonly temperatureDirection: TemperatureDirection;

    readonly swapAnswers: boolean;
    readonly swapWarmCold: boolean;
    readonly cursor?: React.FC<TemperatureCursorProps>;
} & LevelProps;

export const LevelScene = (props: PrivateLevelProps) => {

    const { 
        originalQuestions, temperatureDirection,
        swapAnswers, swapWarmCold,
        warmLegend, coolLegend, cursor
    } = props;
    
    const [questions, setQuestions] = useState<LevelQuestion[]>([]);
    const [popupDisplayed, setPopupDisplayed] = useState<boolean>(false);

    const resultRef = useRef<(Answer|undefined)[]>(Array(originalQuestions.length).fill(undefined));
    useEffect(() => {
        setQuestions(shuffled(props.originalQuestions));
    }, [props.originalQuestions]);

    const statsRef = useRef<LevelStats>({ correct: 0, answered: 0, total: originalQuestions.length });

    const questionCount = questions.length;
    const [question, setQuestion] = useState<number>(1);

    if (questionCount === 0) {
        return <>LOADING</>;
    }

    
    const onOk = () => onAnswer("Ok");
    const onNotOk = () => onAnswer("NotOk");

    const endLevel = () => {
        props.setAnswers(resultRef.current);
        props.onLevelEnd();
    };

    const { content, index, correctAnswer } = questions[question - 1];

    const onAnswer = (answer: Answer) => {
        
        if (swapAnswers) {
            answer = (answer === "Ok" ? "NotOk" : "Ok");
        }

        resultRef.current[index] = answer;

        if (question < questionCount) {
            setQuestion(question + 1);
        }
        else {
            endLevel();
        }

        const oldStats = statsRef.current;
        const newStats: LevelStats = {
            ...oldStats,
            answered: oldStats.answered + 1,
            correct: oldStats.correct + ((answer === correctAnswer) ? 1 : 0)
        }
        
        statsRef.current = newStats;
    }

    const temperaturePercentage = getTemperature(statsRef.current, temperatureDirection);

    const onTimeout = () => {
        endLevel();
    }

    const okIcon = swapWarmCold ? coolBtn : warmBtn;
    const okLegend = swapWarmCold ? coolLegend : warmLegend;
    const okLegendColor = swapWarmCold ? coolColor : warmColor;
    
    const notOkIcon = swapWarmCold ? warmBtn : coolBtn;
    const notOkLegend = swapWarmCold ? warmLegend : coolLegend;
    const notOkLegendColor = swapWarmCold ? warmColor : coolColor;

    const cursorFC = cursor ?? TemperatureCursor;

    return <div className="game-level w-100 h-100">
        <div className="d-flex flex-column w-100 h-100 align-items-center justify-content-evenly">
            <h1 className="w-75 text-center">{props.title}</h1>
            <div>{question} / {questionCount}</div>
            <SwipableCardStack
                onOk={onOk}
                onNotOk={onNotOk}
                {...{
                    okIcon, okLegend, okLegendColor,
                    notOkIcon, notOkLegend, notOkLegendColor
                }}
                onCardClick={() => setPopupDisplayed(true)}
            >
                {content}
            </SwipableCardStack>
            {cursorFC({
                percentage: temperaturePercentage
            })}
        </div>
        <Popup displayed={popupDisplayed} onContinueBtnClicked={() => setPopupDisplayed(false)}>
            {content}
        </Popup>
        <Timer duration={180.0} urgentThreshold={15.0} almostOverThreshold={5.0} onTimeout={onTimeout} />
    </div>;
}