import { CSSProperties } from 'react';
import { ReactComponent as TimerSVG } from '../Images/timer.svg';
import { ReactComponent as StartSVG } from '../Images/start.svg';
import { ReactComponent as RetrySVG } from '../Images/try.svg';
import { ReactComponent as CorrectSVG } from '../Images/correct.svg';
import { ReactComponent as ContinueSVG } from '../Images/continue.svg';
import { ReactComponent as FinishSVG } from '../Images/finish.svg';
import { ReactComponent as WinterSVG } from '../Images/winter.svg';
import { ReactComponent as SummerSVG } from '../Images/summer.svg';
import { ReactComponent as FingerYesSVG } from '../Images/fingeryes.svg';
import { ReactComponent as ColdSVG } from '../Images/cold.svg';
import { ReactComponent as ClapSVG } from '../Images/clap.svg';
import { ReactComponent as HotSVG } from '../Images/hot.svg';
import { ReactComponent as MagnifyingGlassSVG } from '../Images/magnifying-glass.svg';

import './../Styles/Icons.scss';

export type IconColor = "Green"|"Yellow"|"DarkGreen"|"LightGreen";
type IconProps = {
    readonly color?: IconColor;
    readonly outlineColor?: IconColor;
    readonly className?: string;
    readonly style?: CSSProperties;
}

export const ContinueIcon = (props: IconProps) => <Icon {...props}>
    <ContinueSVG />
</Icon>

export const RetryIcon = (props: IconProps) => <Icon {...props}>
    <RetrySVG />
</Icon>

export const CorrectIcon = (props: IconProps) => <Icon {...props}>
    <CorrectSVG />
</Icon>

export const StartIcon = (props: IconProps) => <Icon {...props}>
    <StartSVG />
</Icon>

export const WinterIcon = (props: IconProps) => <Icon {...props}>
    <WinterSVG />
</Icon>

export const SummerIcon = (props: IconProps) => <Icon {...props}>
    <SummerSVG />
</Icon>

export const ColdIcon = (props: IconProps) => <Icon {...props}>
    <ColdSVG />
</Icon>

export const HotIcon = (props: IconProps) => <Icon {...props}>
    <HotSVG />
</Icon>

export const FinishIcon = (props: IconProps) => <Icon {...props}>
    <FinishSVG />
</Icon>

export const FingerYesIcon = (props: IconProps) => <Icon {...props}>
    <FingerYesSVG />
</Icon>

export const TimerIcon = (props: IconProps) => <Icon {...props} hasShadow={false}>
    <TimerSVG />
</Icon>

export const ClapIcon = (props: IconProps) => <Icon {...props} hasShadow={false}>
    <ClapSVG />
</Icon>

export const MagnifyingGlassIcon = (props: IconProps) => <Icon {...props} hasShadow={false}>
    <MagnifyingGlassSVG />
</Icon>

type PrivateIconProps = {
    readonly children: JSX.Element;
    readonly hasShadow?: boolean;
} & IconProps

const Icon = ({ color, hasShadow, className, outlineColor, style, children }: PrivateIconProps) => {

    const classes = [
        "svg-icon"
    ];

    if (hasShadow ?? true) {
        classes.push("svg-icon-shadow");
    }

    if (className) {
        classes.push(className);
    }

    if (color === "Green") {
        classes.push("svg-icon-green");
    }
    else if (color === "Yellow") {
        classes.push("svg-icon-yellow");
    }
    else if (color === "DarkGreen") {
        classes.push("svg-icon-darkgreen");
    }
    else if (color === "LightGreen") {
        classes.push("svg-icon-lightgreen");
    }

    if (outlineColor === "Green") {
        classes.push("svg-outline-green");
    }
    else if (outlineColor === "Yellow") {
        classes.push("svg-outline-yellow");
    }

    return <div className={classes.join(" ")} style={style}>
        {children}
    </div>;
}