import { useState } from 'react';
import './App.scss';
import 'bootstrap/dist/css/bootstrap.css';

import { Answer, BaseGameLevel, DomTomGameLevel, nextBaseStep, nextDomTomStep } from './Game/GameLogic';
import { DomTomLevel1Scene, DomTomLevel2Scene, SummerLevelScene, WinterLevelScene } from './Components/Levels';
import { DomTomLevel1ExplanationScene, DomTomLevel2ExplanationScene, SummerLevelExplanationsScene, WinterLevelExplanationsScene } from './Components/Explanations';
import HomeScreen from './Components/HomeScreen';
import BaseTutorial, { DomTomTutorial } from './Components/Tutorial';
import { DomTomLevel1IntroScene, DomTomLevel2IntroScene, SummerLevelIntroScene, WinterLevelIntroScene } from './Components/LevelIntros';
import Questions from './Game/GameDatabase';
import { DomTomLevel1ResultScene, DomTomLevel2ResultScene, SummerLevelResultScene, WinterLevelResultScene } from './Components/LevelResults';
import EndScreen from './Components/EndScreen';
import { AppContext } from './Contexts/AppContext';

const App = () => {
  
    const swipeType = process.env.REACT_APP_DOM_TOM ? "DomTom": "Base";

    return <AppContext.Provider value={{ swipeType }}>
        {swipeType === "Base" ? <BaseApp /> : <DomTomApp />}
    </AppContext.Provider>
}

const BaseApp = () => {

    const [summerPlayed, setSummerPlayed] = useState<boolean>(false);
    const [winterPlayed, setWinterPlayed] = useState<boolean>(false);

    const [step, setStep] = useState<BaseGameLevel>(BaseGameLevel.HomeScreen);
    const goToNextStep = () => setStep(nextBaseStep(step, summerPlayed, winterPlayed));

    const [lvl1Answers, setLvl1Answers] = useState<(Answer|undefined)[]>(Array(Questions.SummerLevel.length).fill(undefined));
    const [lvl2Answers, setLvl2Answers] = useState<(Answer|undefined)[]>(Array(Questions.WinterLevel.length).fill(undefined));

    const stepMap: Map<BaseGameLevel, JSX.Element> = new Map([
        [BaseGameLevel.HomeScreen, <HomeScreen onLevelEnd={goToNextStep} />],
        [BaseGameLevel.Tutorial, <BaseTutorial 
            onSummerSelected={() => setStep(BaseGameLevel.SummerLevelIntro)}
            onWinterSelected={() => setStep(BaseGameLevel.WinterLevelIntro)}
        />],

        [BaseGameLevel.SummerLevelIntro, <SummerLevelIntroScene onLevelEnd={goToNextStep} />],
        [BaseGameLevel.SummerLevel, <SummerLevelScene onLevelEnd={goToNextStep} setAnswers={setLvl1Answers} />],
        [BaseGameLevel.SummerLevelResults, <SummerLevelResultScene
            questions={Questions.SummerLevel}
            answers={lvl1Answers}
            onLevelEnd={() => {
                setSummerPlayed(true);
                goToNextStep();
            }}
            onRestart={() => setStep(BaseGameLevel.SummerLevel)}
        />],
        [BaseGameLevel.SummerLevelExplanations, <SummerLevelExplanationsScene
            questions={Questions.SummerLevel}
            answers={lvl1Answers}
            onLevelEnd={goToNextStep}
        />],

        [BaseGameLevel.WinterLevelIntro, <WinterLevelIntroScene onLevelEnd={goToNextStep} />],
        [BaseGameLevel.WinterLevel, <WinterLevelScene onLevelEnd={goToNextStep} setAnswers={setLvl2Answers} />],
        [BaseGameLevel.WinterLevelResults, <WinterLevelResultScene
            questions={Questions.WinterLevel}
            answers={lvl2Answers}
            onLevelEnd={() => {
                setWinterPlayed(true);
                goToNextStep();
            }}
            onRestart={() => setStep(BaseGameLevel.WinterLevel)}
        />],
        [BaseGameLevel.WinterLevelExplanations, <WinterLevelExplanationsScene
            questions={Questions.WinterLevel}
            answers={lvl2Answers}
            onLevelEnd={goToNextStep}
        />],
        
        [BaseGameLevel.EndScreen, <EndScreen
            onLevelEnd={() => {
                setStep(BaseGameLevel.SummerLevelIntro);
                setWinterPlayed(false);
                setSummerPlayed(false);
            }}
            onRestart={() => {
                setStep(BaseGameLevel.HomeScreen);
                setWinterPlayed(false);
                setSummerPlayed(false);
            }}
        />]
    ])

    const content = stepMap.get(step);
    return <div className="app d-flex flex-column align-items-center justify-content-evenly">
        {content}
    </div>
}

const DomTomApp = () => {

    const [step, setStep] = useState<DomTomGameLevel>(DomTomGameLevel.HomeScreen);
    const goToNextStep = () => setStep(nextDomTomStep(step));

    const [lvl1Answers, setLvl1Answers] = useState<(Answer|undefined)[]>(Array(Questions.DomTomLevel1.length).fill(undefined));
    const [lvl2Answers, setLvl2Answers] = useState<(Answer|undefined)[]>(Array(Questions.DomTomLevel2.length).fill(undefined));

    const stepMap: Map<DomTomGameLevel, JSX.Element> = new Map([
        [DomTomGameLevel.HomeScreen, <HomeScreen onLevelEnd={goToNextStep} />],
        [DomTomGameLevel.Tutorial, <DomTomTutorial onReady={goToNextStep} />],

        [DomTomGameLevel.Level1Intro, <DomTomLevel1IntroScene onLevelEnd={goToNextStep} />],
        [DomTomGameLevel.Level1, <DomTomLevel1Scene onLevelEnd={goToNextStep} setAnswers={setLvl1Answers} />],
        [DomTomGameLevel.Level1Results, <DomTomLevel1ResultScene
            questions={Questions.DomTomLevel1}
            answers={lvl1Answers}
            onLevelEnd={goToNextStep}
            onRestart={() => setStep(DomTomGameLevel.Level1)}
        />],
        [DomTomGameLevel.Level1Explanations, <DomTomLevel1ExplanationScene
            questions={Questions.DomTomLevel1}
            answers={lvl1Answers}
            onLevelEnd={goToNextStep}
        />],

        [DomTomGameLevel.Level2Intro, <DomTomLevel2IntroScene onLevelEnd={goToNextStep} />],
        [DomTomGameLevel.Level2, <DomTomLevel2Scene onLevelEnd={goToNextStep} setAnswers={setLvl2Answers} />],
        [DomTomGameLevel.Level2Results, <DomTomLevel2ResultScene
            questions={Questions.DomTomLevel2}
            answers={lvl2Answers}
            onLevelEnd={goToNextStep}
            onRestart={() => setStep(DomTomGameLevel.Level2)}
        />],
        [DomTomGameLevel.Level2Explanations, <DomTomLevel2ExplanationScene
            questions={Questions.DomTomLevel2}
            answers={lvl2Answers}
            onLevelEnd={goToNextStep}
        />],

        [DomTomGameLevel.EndScreen, <EndScreen
            onLevelEnd={() => setStep(DomTomGameLevel.Level1Intro)}
            onRestart={() => setStep(DomTomGameLevel.Level1Intro)}
        />]
    ])

    const content = stepMap.get(step);
    return <div className="app d-flex flex-column align-items-center justify-content-evenly">
        {content}
    </div>
}

export default App;