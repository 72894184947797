import Images from "../Components/Images";
import { Question  } from "./GameLogic";

module Questions {

    let index = 0;
    export const SummerLevel: Question[] = [
        {
            index: index++,
            correctAnswer: "Ok",
            content: <Images.SummerLevel.Question1 />,
            explanation: "Les murs en pierre sont épais. Ils permettent donc une bonne inertie thermique : ils protègent de la chaleur en journée et la restituent la nuit, quand il fait plus frais."
        },
        {
            index: index++,
            correctAnswer: "Ok",
            content: <Images.SummerLevel.Question2 />,
            explanation: "Les points d'eau sont idéaux pour rafraîchir les espaces alentours en été."
        },
        {
            index: index++,
            correctAnswer: "Ok",
            content: <Images.SummerLevel.Question3 />,
            explanation: "La végétation aux alentours d'un bâtiment permet de rafraîchir l'habitat."
        },
        {
            index: index++,
            correctAnswer: "Ok",
            content: <Images.SummerLevel.Question4 />,
            explanation: "Les balcons les uns sous les autres permettent de faire de l'ombre, les loggias sont protégées du soleil en été lorsque la course du soleil est plus haute."
        },
        {
            index: index++,
            correctAnswer: "NotOk",
            content: <Images.SummerLevel.Question5 />,
            explanation: "Les arbres à feuillage caduque doivent être situés au Sud pour protéger du soleil avec leurs feuilles."
        },
        {
            index: index++,
            correctAnswer: "NotOk",
            content: <Images.SummerLevel.Question6 />,
            explanation: "Le plancher et la moquette sont des matériaux avec peu d'inertie thermique (ils sont moins lourds que la pierre par exemple). Ils emmagasinent peu la chaleur en été avant de la restituer, il fera donc chaud dans la pièce s'il y a de fortes chaleurs !"
        },
        {
            index: index++,
            correctAnswer: "NotOk",
            content: <Images.SummerLevel.Question7 />,
            explanation: "La cuisine dispose de deux ouvertures au Sud et à l’Ouest, ce qui risque de surchauffer la pièce en été."
        },
        {
            index: index++,
            correctAnswer: "Ok",
            content: <Images.SummerLevel.Question8 />,
            explanation: "La chambre 2 exposée Sud dispose d’une fenêtre moins grande que celle du séjour pour limiter les apports solaires en été et donc les surchauffes. La chambre 1, la salle de bain, le séchoir et la cuisine disposent de petites fenêtres orientées Nord pour garantir des températures agréables en été."
        },
        {
            index: index++,
            correctAnswer: "Ok",
            content: <Images.SummerLevel.Question9 />,
            explanation: "L'ouverture Sud dans une pièce de vie (cuisine) est idéale lorsqu'elle est couplée à de la végétation à feuillage caduque."
        },
        {
            index: index++,
            correctAnswer: "NotOk",
            content: <Images.SummerLevel.Question10 />,
            explanation: "L'ouverture Sud de la chambre sans végétation risque de surchauffer la pièce en été."
        },
        {
            index: index++,
            correctAnswer: "Ok",
            content: <Images.SummerLevel.Question11 />,
            explanation: "Les brise-soleil au-dessus des fenêtres exposées Sud, couplés à de la végétation à feuillage caduque permettent de faire de l'ombre lorsque la course du soleil est plus haute en été. "
        },
        {
            index: index++,
            correctAnswer: "Ok",
            content: <Images.SummerLevel.Question12 />,
            explanation: "La pergola permet de faire de l'ombre en été, lorsque la course du soleil est plus haute."
        }
    ]


    index = 0;
    export const WinterLevel: Question[] = [
        {
            index: index++,
            correctAnswer: "Ok",
            content: <Images.WinterLevel.Question1 />,
            explanation: "Les petites ouvertures au Nord permettent d'apporter un peu de lumière et de ne pas laisser passer trop de froid."
        },
        {
            index: index++,
            correctAnswer: "Ok",
            content: <Images.WinterLevel.Question2 />,
            explanation: "Les feuillages persistants au Nord protègent l'habitat du vent et du froid."
        },
        {
            index: index++,
            correctAnswer: "Ok",
            content: <Images.WinterLevel.Question3 />,
            explanation: "L'exposition sud du séjour garantit un apport solaire (lumière et chaleur) optimal !"
        },
        {
            index: index++,
            correctAnswer: "NotOk",
            content: <Images.WinterLevel.Question4 />,
            explanation: "Garder ses volets fermés le jour en hiver n'est pas une bonne pratique : en effet, ils coupent du soleil et donc de la lumière et de la chaleur naturelle !"
        },
        {
            index: index++,
            correctAnswer: "Ok",
            content: <Images.WinterLevel.Question5 />,
            explanation: "Les murs et les sols épais permettent d'emmagasiner la chaleur la journée pour la diffuser plus tard dans la soirée et dans la nuit."
        },
        {
            index: index++,
            correctAnswer: "Ok",
            content: <Images.WinterLevel.Question6 />,
            explanation: "Plutôt que d'augmenter le chauffage, enfiler un pull permet de faire des économies d'énergies et de garantir son confort !"
        },
        {
            index: index++,
            correctAnswer: "Ok",
            content: <Images.WinterLevel.Question7 />,
            explanation: "Des ouvertures orientées Est dans les chambres à coucher sont idéales puisqu'elles permettent de maintenir des températures modérées tout au long de l'année."
        },
        {
            index: index++,
            correctAnswer: "NotOk",
            content: <Images.WinterLevel.Question8 />,
            explanation: "Bien que les verrières au plafond fassent rentrer un maximum de lumière, cette ouverture zénithale ne prend pas en compte la course du soleil plus basse en hiver et ne permet pas une large pénétration des rayons du soleil pour réchauffer l'espace."
        },
        {
            index: index++,
            correctAnswer: "NotOk",
            content: <Images.WinterLevel.Question9 />,
            explanation: "Les espaces tampons (entrée, cellier...) doivent être situés au Nord pour isoler l'habitation du froid."
        },
        {
            index: index++,
            correctAnswer: "Ok",
            content: <Images.WinterLevel.Question10 />,
            explanation: "Les grandes loggias permettent de capter la lumière et la chaleur du soleil lorsque la course du soleil est plus basse en hiver."
        },
        {
            index: index++,
            correctAnswer: "NotOk",
            content: <Images.WinterLevel.Question11 />,
            explanation: "L'absence de relief et de végétation (arbres à feuillage persistant) expose la maison aux vents, parfois très froids en hiver."
        },
        {
            index: index++,
            correctAnswer: "NotOk",
            content: <Images.WinterLevel.Question12 />,
            explanation: "La pièce de vie est exposée au Nord, ce qui rend l'espace moins ensoleillé, donc plus sombre et plus difficile à réchauffer."
        }
    ]

    index = 0;
    export const DomTomLevel1: Question[] = [
        {
            index: index++,
            correctAnswer: "Ok",
            content: <Images.DomTom1Level.Question1 />,
            explanation: "Bien que de couleur sombre, la façade et les menuiseries sont entièrement protégées des rayons directs du soleil grâce à des brise-soleil orientables."
        },
        {
            index: index++,
            correctAnswer: "NotOk",
            content: <Images.DomTom1Level.Question2 />,
            explanation: "Les façades du bâtiment sont très vitrées et elles n'ont aucune protection solaire. Si la climatisation tombe en panne, ce bâtiment devient un four solaire !"
        },
        {
            index: index++,
            correctAnswer: "NotOk",
            content: <Images.DomTom1Level.Question3 />,
            explanation: "Certainement pour un choix esthétique, le bâtiment et sa toiture sont de couleur très sombre, cela peut capter deux fois plus de chaleur que les couleurs claires."
        },
        {
            index: index++,
            correctAnswer: "Ok",
            content: <Images.DomTom1Level.Question4 />,
            explanation: "Ce bâtiment est de couleur claire, sa façade est protégée et ses abords sont végétalisés, tout est fait pour limiter les apports de chaleur."
        },
        {
            index: index++,
            correctAnswer: "NotOk",
            content: <Images.DomTom1Level.Question5 />,
            explanation: "Le soleil tape directement à l'intérieur du bâtiment, cela entraîne d'importants apports de chaleur qui apportent de l'inconfort et/ou de fortes consommations de climatisation."
        },
        {
            index: index++,
            correctAnswer: "Ok",
            content: <Images.DomTom1Level.Question6 />,
            explanation: "Ce magasin protège sa façade vitrée des rayons du soleil par un store banne présent sur toute sa longueur."
        },
        {
            index: index++,
            correctAnswer: "NotOk",
            content: <Images.DomTom1Level.Question7 />,
            explanation: "En climat tropical, les trappes de désenfumages ne doivent pas laisser passer la lumière car cela représente de très importants apports solaires."
        },
        {
            index: index++,
            correctAnswer: "NotOk",
            content: <Images.DomTom1Level.Question8 />,
            explanation: "Comme le montre l'ombre, ces casquettes solaires sont trop petites et elles ne protègent pas suffisamment les fenêtres des rayons directs du soleil."
        },
        {
            index: index++,
            correctAnswer: "Ok",
            content: <Images.DomTom1Level.Question9 />,
            explanation: "Les fenêtres sont intégralement à l'ombre grâce aux brise-soleil."
        },
        {
            index: index++,
            correctAnswer: "Ok",
            content: <Images.DomTom1Level.Question10 />,
            explanation: "La toiture du bâtiment est de couleur claire et les coursives permettent de protéger les façades du rayonnement direct."
        },
        {
            index: index++,
            correctAnswer: "NotOk",
            content: <Images.DomTom1Level.Question11 />,
            explanation: "La façade est de couleur sombre et les ouvertures n'ont aucune protection solaire."
        },
        {
            index: index++,
            correctAnswer: "Ok",
            content: <Images.DomTom1Level.Question12 />,
            explanation: "Dans cet environnement urbain, la façade du bâtiment est entièrement protégée."
        }
    ]

    index = 0;
    export const DomTomLevel2: Question[] = [
        {
            index: index++,
            correctAnswer: "Ok",
            content: <Images.DomTom2Level.Question1 />,
            explanation: "Ces logements ont des fenêtres de type jalousie ou persienne, cela est favorable à la ventilation naturelle."
        },
        {
            index: index++,
            correctAnswer: "Ok",
            content: <Images.DomTom2Level.Question2 />,
            explanation: "L'îlot central de ce bâtiment est végétalisé pour apporter de la fraicheur et permet une meilleure ventilation traversante des espaces."
        },
        {
            index: index++,
            correctAnswer: "NotOk",
            content: <Images.DomTom2Level.Question3 />,
            explanation: "Ce bâtiment a un environnement extérieur entièrement bétonné ce qui apporte beaucoup de chaleur."
        },
        {
            index: index++,
            correctAnswer: "Ok",
            content: <Images.DomTom2Level.Question4 />,
            explanation: "L'habitat créole est très favorable à la ventilation naturelle et au rafraîchissement passif avec ses grandes varangues."
        },
        {
            index: index++,
            correctAnswer: "Ok",
            content: <Images.DomTom2Level.Question5 />,
            explanation: "Les brasseurs d'air consomment beaucoup moins d'énergie que la climatisation, ils apportent une sensation de fraîcheur lorsque le vent est insuffisant."
        },
        {
            index: index++,
            correctAnswer: "Ok",
            content: <Images.DomTom2Level.Question6 />,
            explanation: "L'ensemble des logements du plan sont traversant, cela signifie que les façades ont 2 orientations opposées, cela est très favorable à la ventilation naturelle."
        },
        {
            index: index++,
            correctAnswer: "Ok",
            content: <Images.DomTom2Level.Question7 />,
            explanation: "Ces grandes jalousies permettent une forte ventilation naturelle de l'espace. L'ouverture de la jalousie peut être modulée en fonction du besoin."
        },
        {
            index: index++,
            correctAnswer: "NotOk",
            content: <Images.DomTom2Level.Question8 />,
            explanation: "Les façades de ce bâtiment ont peu de protection solaire et les fenêtres ne sont pas adaptées à la ventilation naturelle."
        },
        {
            index: index++,
            correctAnswer: "Ok",
            content: <Images.DomTom2Level.Question9 />,
            explanation: "Ce bâtiment a un environnement extérieur très végétalisé ce qui apporte de la fraîcheur."
        },
        {
            index: index++,
            correctAnswer: "NotOk",
            content: <Images.DomTom2Level.Question10 />,
            explanation: "Ce logement a très peu d'ouvertures (faible porosité) et il n'y a pas d'ouvertures sur des façades opposées, cela n'est pas favorable à la ventilation naturelle."
        },
        {
            index: index++,
            correctAnswer: "Ok",
            content: <Images.DomTom2Level.Question11 />,
            explanation: "Le bâtiment est conçu pour permettre une ventilation entièrement traversante afin d'apporter du confort aux occupants sans climatisation."
        },
        {
            index: index++,
            correctAnswer: "Ok",
            content: <Images.DomTom2Level.Question12 />,
            explanation: "Cette pièce a de nombreuses ouvertures (porosité de façade élevée) ce qui est favorable à la ventilation naturelle."
        }
    ]
}

export default Questions;