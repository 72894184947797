import { DragDropContext, Draggable, DraggingStyle, Droppable, DropResult, NotDraggingStyle } from 'react-beautiful-dnd';
import './../Styles/SwipableCardStack.scss';

import { range } from '../Services/Helpers';
import { CSSProperties, useRef } from 'react';
import { IconColor } from './Icons';

type SwipableCardStackProps = {
    readonly additionalCardCount?: number;
    readonly children: JSX.Element;

    readonly okIcon?: JSX.Element;
    readonly notOkIcon?: JSX.Element;

    readonly onOk?: Function;
    readonly onNotOk?: Function;

    readonly okEnabled?: boolean;
    readonly notOkEnabled?: boolean;

    readonly okLegend?: string;
    readonly okLegendColor?: IconColor;
    readonly notOkLegend?: string;
    readonly notOkLegendColor?: IconColor;

    readonly canClickButtons?: boolean;

    readonly nextCardContent?: JSX.Element;

    readonly onCardClick?: Function;
}

const SwipableCardStack = (props: SwipableCardStackProps) => {
    const { 
        children, nextCardContent, canClickButtons,
        okLegend, notOkLegend,
        okLegendColor, notOkLegendColor,
        onCardClick,
        okIcon, notOkIcon,
        onOk, onNotOk
    } = props;

    const swipeOkId = "swipeOk";
    const swipeNotOkId = "swipeNotOk";
    const originalZoneId = "originalZone";

    const okEnabled = props.okEnabled ?? (okIcon !== undefined && onOk !== undefined);
    const notOkEnabled = props.notOkEnabled ?? (notOkIcon !== undefined && onNotOk !== undefined);
    
    const additionalCardCount = props.additionalCardCount ?? 0;

    const okFunction = () => {
        if (okEnabled && onOk) {
            onOk();
        }
    }

    const notOkFunction = () => {
        if (notOkEnabled && onNotOk) {
            onNotOk();
        }
    }

    const onDragEnd = (result: DropResult) => {

        const { reason, destination } = result;
        if (reason === "DROP" && destination) {
            
            if (destination.droppableId === swipeNotOkId) {
                notOkFunction();
            }

            if (destination.droppableId === swipeOkId) {
                okFunction();
            }
        }
    }

    const clickable = canClickButtons ?? true;
    const onBtnClicked = (callback: Function) => {
        if (clickable) {
            callback();
        }
    }
    const onOkBtnClicked = () => onBtnClicked(okFunction);
    const onNotOkBtnClicked = () => onBtnClicked(notOkFunction);

    const columnContentClass = "h-100 d-flex flex-column justify-content-center align-items-center";

    return <div className="swipable-cardstack d-flex flex-row justify-content-evenly w-100">
        <DragDropContext onDragEnd={onDragEnd}>
            <IconColumn>
                <Droppable droppableId={swipeNotOkId} isDropDisabled={!notOkEnabled}>
                    {(provided) => <div ref={provided.innerRef} {...provided.droppableProps} className="h-100">
                        <div className={columnContentClass}>
                            <EmptyPlaceHolder placeHolder={provided.placeholder} />
                            <SwipeIcon clickable={clickable && notOkEnabled} onClick={onNotOkBtnClicked}>
                                {notOkIcon ?? <EmptyIcon />}
                            </SwipeIcon>
                            <Legend legend={notOkLegend} legendColor={notOkLegendColor} />
                        </div>
                    </div>}
                </Droppable>
            </IconColumn>
            <div className="cardstack">
                {range(additionalCardCount).map(i => {

                    const classes = [
                        "card",
                        "card-animation",
                    ];
                    let cardContent = undefined;

                    if (i === additionalCardCount - 1) {
                        cardContent = nextCardContent;
                        classes.push("card-next");
                    }

                    return <div key={`card-${i}`} className={classes.join(" ")}>
                        <CardContent>
                            {cardContent}
                        </CardContent>
                    </div>
                })}
                <Droppable droppableId={originalZoneId}>
                    {(provided) => <div className="card-dropzone card-animation" ref={provided.innerRef}>
                        <Draggable draggableId={"dragable-card"} index={1}>
                            {(dragProvided, { isDragging, isDropAnimating, draggingOver }) => {
                                const classes = [
                                    "card",
                                    "card-main"
                                ]

                                if (isDragging) {
                                    classes.push("card-dragging");
                                }
                                if (isDropAnimating) {
                                    classes.push("card-dropping");
                                }
                                
                                if (!isDragging && !isDropAnimating && onCardClick) {
                                    classes.push("card-clickable");
                                }

                                return <CardAnimation
                                    isDragging={isDragging}
                                    isDropping={isDropAnimating}
                                    customAnimateDrop={draggingOver !== originalZoneId}
                                    style={dragProvided.draggableProps.style}
                                >
                                    {(style) => <div
                                        ref={dragProvided.innerRef}
                                        className={classes.join(" ")}
                                        {...dragProvided.draggableProps}
                                        {...dragProvided.dragHandleProps}
                                        style={style}
                                        onClick={() => onCardClick && onCardClick()}
                                    >
                                        <CardContent>
                                            {children}
                                        </CardContent>
                                    </div>}
                                </CardAnimation>}
                            }
                        </Draggable>
                        <EmptyPlaceHolder placeHolder={provided.placeholder} />
                    </div>} 
                </Droppable>
            </div>
            <IconColumn>
                <Droppable droppableId={swipeOkId} isDropDisabled={!okEnabled}>
                    {(provided) => <div ref={provided.innerRef} {...provided.droppableProps} className="h-100">
                        <div className={columnContentClass}>
                            <EmptyPlaceHolder placeHolder={provided.placeholder} />
                            <SwipeIcon clickable={clickable && okEnabled} onClick={onOkBtnClicked}>
                                {okIcon ?? <EmptyIcon />}
                            </SwipeIcon>
                            <Legend legend={okLegend} legendColor={okLegendColor}/>
                        </div>
                    </div>}
                </Droppable>
            </IconColumn>
        </DragDropContext>
    </div>
}

type IconColumnProps = {
    readonly children: JSX.Element;
}

const getAdditionalLegendColor = (color: IconColor|undefined): string => {
    
    if (color === "Green") {
        return "legend-green";
    }
    else if (color === "Yellow") {
        return "legend-yellow";
    }
    else {
        return "";
    }
}

const IconColumn = (props: IconColumnProps) => <div className="d-flex flex-column h-100 justify-content-center align-items-center">
    {props.children}
</div>

type LegendProps = {
    readonly legend?: string;
    readonly legendColor?: IconColor;
}

const Legend = (props: LegendProps) => <>
    {props.legend && <div className={`text-center w-100 legend ${getAdditionalLegendColor(props.legendColor)}`}>
        {props.legend}
    </div>}
</>;

type CardAnimationFn = (style: CSSProperties) => JSX.Element;

type NaturalDragAnimationState = {
    readonly prevX: number,
    readonly prevY: number,
    readonly prevRotation: number
}
const defaultDragAnimationState: NaturalDragAnimationState = {
    prevX: 0,
    prevY: 0,
    prevRotation: 0
}

type CardAnimationProps = {
    
    readonly style: DraggingStyle|NotDraggingStyle|undefined;
    readonly isDragging: boolean;
    readonly isDropping: boolean;
    readonly customAnimateDrop: boolean;

    readonly animationRotationFade?: number,
    readonly rotationMultiplier?: number,

    readonly dropTargetScale?: number,
    readonly dropTargetOpacity?: number,
    readonly dropDuration?: number,
    readonly dropTransition?: string,

    readonly children: CardAnimationFn;
}

type CardDropAnimationProps = {
    readonly opacity?: number,
    readonly transition?: string
}

const CardAnimation = (props: CardAnimationProps) => {
    
    const { style, isDragging, isDropping, customAnimateDrop, children } = props;

    const naturalDragRef = useRef<NaturalDragAnimationState>(defaultDragAnimationState);
    const transformRef = useRef<string>('');
    const dropAnimationRef = useRef<CardDropAnimationProps>({});

    const patchStyle = () => {

        if (isDragging && !isDropping) {
            const draggingStyle = style as DraggingStyle;
            const transform = draggingStyle.transform;

            const animationRotationFade = props.animationRotationFade ?? 0.9;
            const rotationMultiplier = props.rotationMultiplier ?? 0.9;

            const sigmoid = (x: number) => x / (1.0 + Math.abs(x));

            if (transform) {

                const animationState = naturalDragRef.current;

                const [currentX, currentY] = transform
                    .match(/translate\(.{1,}\)/g)![0]
                    .match(/-?[0-9]{1,}/g)!
                    .map(str => parseInt(str));

                const velocity = currentX - animationState.prevX;
                const rotation = animationState.prevRotation * animationRotationFade + sigmoid(velocity) * rotationMultiplier;
                
                naturalDragRef.current = {
                    prevX: currentX,
                    prevY: currentY,
                    prevRotation: rotation
                };
                transformRef.current = `${transform} rotate(${rotation}deg)`;
            }
        }
        else if (isDropping) {   
            const { dropTargetScale, dropTargetOpacity, dropDuration, dropTransition } = {
                dropTargetScale: 0.4,
                dropTargetOpacity: 0.4,
                dropDuration: 300,
                dropTransition: 'ease-out',

                ...props
            };

            const { prevX: x, prevY: y } = naturalDragRef.current;
            
            transformRef.current = `translate(${x}px ${y}px) scale(${dropTargetScale})`;
            dropAnimationRef.current = {
                opacity: dropTargetOpacity,
                transition: `all ${dropTransition} ${dropDuration}ms`
            }
        }
        else {
            naturalDragRef.current = defaultDragAnimationState;
        }
    }
    requestAnimationFrame(patchStyle);

    let patchedStyle: CSSProperties = {
        ...style
    };
    if (isDragging) {

        if (isDropping) {

            if (customAnimateDrop) {
                patchedStyle = {
                    ...patchedStyle,
                    ...dropAnimationRef.current
                }
            }
        }
        else {
            patchedStyle = {
                ...patchedStyle,
                transform: transformRef.current
            };
        }
    }

    return children(patchedStyle);
}

type SwipeIconProps = {
    readonly children: JSX.Element;
    readonly clickable: boolean;
    readonly onClick: Function;
}

const SwipeIcon = ({ children, clickable, onClick }: SwipeIconProps) => {
    
    let className = "swipe-icon icon-dropzone mx-3 mb-2";
    if (clickable) {
        className += " swipe-icon-clickable";
    }
    else {
        className += " swipe-icon-disabled";
    }

    return <div className={className} onClick={() => onClick()}>
        {children}
    </div>
}


const EmptyIcon = () => <div></div>

type EmptyPlaceHolderProps = {
    placeHolder: React.ReactElement<HTMLElement> | null | undefined;
}

const EmptyPlaceHolder = ({ placeHolder }: EmptyPlaceHolderProps) => <div className="empty-placeholder">
    {placeHolder}
</div>;

type CardContentProps = {
    readonly children?: JSX.Element;
}
const CardContent = (props: CardContentProps) => <div className="p-2 w-100 h-100 d-flex d-column align-items-center justify-content-center">
    {props.children}
</div>;

export default SwipableCardStack;