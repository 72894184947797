import { Answer, GameLevelProps, getTemperature, Question, TemperatureDirection } from '../Game/GameLogic';
import { ClapIcon, CorrectIcon, RetryIcon } from './Icons';

import '../Styles/LevelResults.scss';
import { zip } from '../Services/Helpers';
import TemperatureCursor, { DomTomTemperatureCursor, TemperatureCursorProps } from './TemperatureCursor';
import SwipableCardStack from './SwipableCardStack';

type LevelResultProps = {
    readonly questions: Question[];
    readonly answers: (Answer|undefined)[];
    readonly onRestart: () => void;
} & GameLevelProps

export const SummerLevelResultScene = (props: LevelResultProps) => <LevelResult
    badResult="J'espère que ton occupant a une bonne climatisation... Sa maison est encore trop chaude en été, sa consommation d'énergie et ses factures vont continuer à être élevées s'il veut maintenir des températures agréables !"
    averageResult="C'est plutôt pas mal ! Et tu peux encore mieux faire pour garantir encore plus de confort à ton occupant !"
    goodResult="Bravo, ton occupant est ravi : sa maison est parfaitement bioclimatique pour être agréable en été... même sans climatisation !"
    {...props}
    thermometerDirection="HotToCold"
/>


export const WinterLevelResultScene = (props: LevelResultProps) => <LevelResult
    badResult="Allume le chauffage et le feu dans la cheminée... La maison de ton occupant est trop froide pour lui garantir un confort optimal."
    averageResult="C'est plutôt pas mal ! Et tu peux encore mieux faire pour garantir encore plus de confort à ton occupant !"
    goodResult="Félicitations, voilà un intérieur très cosy : il fait bon vivre dans la maison de ton occupant... même sans systèmes de chauffage !"
    {...props}
    thermometerDirection="ColdToHot"
/>

export const DomTomLevel1ResultScene = (props: LevelResultProps) => <LevelResult
    badResult="J'espère que ton client a une bonne climatisation... Sa maison est encore trop chaude, sa consommation d'énergie et ses factures vont continuer à être élevées s'il veut maintenir des températures agréables !"
    averageResult="C'est plutôt pas mal ! Et tu peux encore mieux faire pour garantir encore plus de confort à ton client !"
    goodResult="Bravo, ton client est ravi : sa maison est parfaitement bioclimatique pour être agréable en toute saison... même sans climatisation !"
    {...props}
    cursor={DomTomTemperatureCursor}
    thermometerDirection="HotToComfort"
/>

export const DomTomLevel2ResultScene = (props: LevelResultProps) => <LevelResult
    badResult="Allumez la climatisation au maximum... C'est la seule solution pour rafraîchir la maison de ton client, ça va lui couter cher !"
    averageResult="C'est plutôt pas mal ! Et tu peux encore mieux faire pour garantir encore plus de confort à ton client !"
    goodResult="Félicitations, voilà un intérieur très cosy : il fait bon vivre dans la maison de ton client... même sans systèmes de climatisation !"
    {...props}
    cursor={DomTomTemperatureCursor}
    thermometerDirection="HotToComfort"
/>

type PrivateLevelResultProps = {
    readonly goodResult: string;
    readonly averageResult: string;
    readonly badResult: string;
    readonly thermometerDirection: TemperatureDirection;
    readonly cursor?: React.FC<TemperatureCursorProps>;
} & LevelResultProps;

const LevelResult = (props: PrivateLevelResultProps) => {

    const {
        goodResult, averageResult, badResult,
        onRestart, onLevelEnd,
        thermometerDirection, questions, answers,
        cursor
    } = props;
    const questionCount = questions.length;

    const correctAnswers = zip(questions, answers).filter(([question, answer]) => question.correctAnswer === answer).length;

    const percentageResult = getTemperature({
        correct: correctAnswers,
        total: questionCount,
        answered: questionCount,
    }, thermometerDirection);

    const text = (correctAnswers >= 10) ? goodResult : (correctAnswers >= 7 ? averageResult : badResult);

    const retryBtn = <RetryIcon color="Green" />;
    const correctBtn = <CorrectIcon color="DarkGreen" />;

    const cursorFC = cursor ?? TemperatureCursor;

    return <div className="level-result w-100 min-h-100">
        <div className="d-flex flex-column w-100 min-h-100 align-items-center justify-content-evenly">
            
            <div className="clap-icon-container">
                <ClapIcon color="Yellow" className="w-100" />
            </div>
            <SwipableCardStack
                onNotOk={onRestart}
                notOkIcon={retryBtn}

                onOk={onLevelEnd}
                okIcon={correctBtn}
            >
                <div className={`level-result-text text-center p-4 ${text.length > 150 ? 'long-text': ''}`}>
                    <div className="correct-count">{correctAnswers}</div>
                    <div className="correct-answers mb-4">bonnes réponses !</div>
                    <div className="result-main-text">{text}</div>
                </div>
            </SwipableCardStack>
            {cursorFC({ percentage: percentageResult })}
        </div>
    </div>;
}