import { ReactComponent as SwipingHand } from '../Images/swipinghand.svg';

import './../Styles/SwipeDemo.scss';

interface SwipeDemoProps {

}

const SwipeDemo = (props: SwipeDemoProps) => <div className="swipe-demo h-100">
    <SwipingHand className="h-100" />
</div>

export default SwipeDemo;