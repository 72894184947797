import { useEffect, useRef, useState } from "react";
import { TimerIcon } from "./Icons";

import './../Styles/Timer.scss';

type TimerProps = {
    readonly duration: number;
    readonly urgentThreshold?: number;
    readonly almostOverThreshold?: number;

    readonly onTimeout: Function;
    readonly overLabel?: string;
}

const Timer = (props: TimerProps) => {

    const [time, setTime] = useState<number>(props.duration);
    const [over, setOver] = useState<boolean>(false);

    const timeRef = useRef<number>(props.duration);
    const overRef = useRef<boolean>(false);

    const interval = 30;
    useEffect(() => {
        const timeout = setInterval(() => {
            
            if (overRef.current) {
                return;
            }

            const newTime = timeRef.current - interval / 1000;
            if (newTime > 0.0) {
                setTime(newTime);
                timeRef.current = newTime;
            }
            else {
                props.onTimeout();
                setTime(0.0);
                setOver(true);
                overRef.current = true;
            }
        }, 30);

        return () => clearInterval(timeout);
    }, [props]);

    const label = (over && props.overLabel) ? props.overLabel : `${time.toFixed(1)}s`;
    
    const timerClasses = [
        "timer",
        "d-flex",
        "flew-row",
        "align-items-center"
    ];

    const { almostOverThreshold, urgentThreshold } = props;
    if (over) {
        timerClasses.push("timer-over");
    }
    else if (almostOverThreshold && time < almostOverThreshold) {
        timerClasses.push("timer-almost-over");
    }
    else if (urgentThreshold && time < urgentThreshold) {
        timerClasses.push("timer-urgent"); 
    }

    return <div className={timerClasses.join(" ")}>
        <div className="me-2 h-100">
            <TimerIcon color="Yellow" />
        </div>
        <div className="timer-label">{label}</div>
    </div>;
}

export default Timer;