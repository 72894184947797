export function range(size: number, start: number = 0): number[] {
    const result=[];

    for (let n = start, i=0; i < size; i++, n++) {
        result.push(n);
    }

    return result;
}

export function shuffled<T>(array: T[]): T[] {
    const copy = array.slice();
    for (let i = copy.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = copy[i];
        copy[i] = copy[j];
        copy[j] = temp;
    }

    return copy;
}

export function zip<T1, T2>(array1: T1[], array2: T2[]): ([T1, T2])[] {
    if (array1.length !== array2.length) {
        throw new Error('Arrays must have same length');
    }

    return array1.map((elt, i) => [elt, array2[i]]);
}

export function clamp(value: number, min: number, max: number) {
    return value > min ? (value < max ? value : max) : min;
}