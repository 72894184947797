import './../Styles/TemperatureCursor.scss';

type TemperatureCursorOptionalProps = {
    readonly leftLabel: string;
    readonly middleLabel: string;
    readonly rightLabel: string;
    readonly hasMiddleLabel: boolean;
}

export type TemperatureCursorProps = {
    readonly percentage: number;
} & Partial<TemperatureCursorOptionalProps>

const defaultProps: TemperatureCursorOptionalProps = {
    leftLabel: "Froid",
    middleLabel: "Confort",
    rightLabel: "Chaud",
    hasMiddleLabel: true
}

const TemperatureCursor = (props: TemperatureCursorProps) => {

    const {
        percentage,
        leftLabel,
        middleLabel,
        rightLabel,
        hasMiddleLabel
    } = {
        ...defaultProps,
        ...props
    }

    let cursorClass;
    if (percentage < 33.33) {
        cursorClass = "temperature-cursor-cold";
    }
    else if (percentage < 66.66) {
        cursorClass = "temperature-cursor-comfort";
    }
    else {
        cursorClass = "temperature-cursor-hot";
    }

    const textY = 39;
    const linesY1 = 55;
    const linesY2 = 72;

    const x1 = 90;
    const x3 = 490;
    const x2 = (x1 + x3) / 2.0;

    const centerX = 41;
    const centerY = 108;

    const xMin = 80;
    const xMax = 497;

    const tempX = xMin + (percentage / 100.0) * (xMax - xMin);

    return <div className={`temperature-cursor ${cursorClass} mt-2`}>
        <svg viewBox="0 0 534 149">

            <g className="thermometer-coloured-group">
                <circle cx={centerX} cy={centerY} r="22" />
                <line x1={centerX} y1={centerY} x2={tempX} y2={centerY} />
            </g>

            <g className="thermometer-black-elements">

                <path d="M74.4304 132.052L499.86 132.052C513.149 132.052 523.939 121.301 523.939 108.058C523.939 94.8159 513.149 84.0641 499.86 84.0641L74.4304 84.0641C66.6887 73.373 54.436 67.0556 41.147 67.0556C18.4705 66.9948 0 85.4004 0 107.997C0 130.594 18.4705 149 41.147 149C54.436 149 66.6887 142.683 74.4304 132.052ZM9.99721 107.997C9.99721 90.8675 23.9567 76.9569 41.147 76.9569C51.9367 76.9569 61.812 82.424 67.5421 91.5964L69.0051 93.9047L499.86 93.9047C507.663 93.9047 514.003 100.222 514.003 107.997C514.003 115.773 507.663 122.09 499.86 122.09L69.0051 122.09L67.5421 124.398C61.7511 133.571 51.9367 139.038 41.147 139.038C23.9567 139.038 9.99721 125.127 9.99721 107.997Z" />
                
                <line x1={x1} y1={linesY1} x2={x1} y2={linesY2} />
                {hasMiddleLabel && <line x1={x2} y1={linesY1} x2={x2} y2={linesY2} />}
                <line x1={x3} y1={linesY1} x2={x3} y2={linesY2} />

                <text x={x1} y={textY}>{leftLabel}</text>
                {hasMiddleLabel && <text x={x2} y={textY}>{middleLabel}</text>}
                <text x={x3} y={textY}>{rightLabel}</text>
            </g>

        </svg>
    </div>;
}


const defaultDomTomProps: TemperatureCursorOptionalProps = {
    leftLabel: "Confort",
    middleLabel: "",
    rightLabel: "Trop chaud",
    hasMiddleLabel: false
}

export const DomTomTemperatureCursor = (props: TemperatureCursorProps) => <TemperatureCursor {...{ ...defaultDomTomProps, ...props }}/>

export default TemperatureCursor;