import { GameLevelProps } from '../Game/GameLogic';
import { StartIcon } from './Icons';

import SummerBackgroundPng from '../Images/Intros/summer-intro-background.png';
import WinterBackgroundPng from '../Images/Intros/winter-intro-background.png';

import '../Styles/LevelIntros.scss';
import SwipableCardStack from './SwipableCardStack';


export const SummerLevelIntroScene = (props: GameLevelProps) => <LevelIntroScene
    background={<SummerLevelIntroBackground />} {...props}
>
    <div className="w-100">
        <SwipableCardStack
            okIcon={<StartIcon color="Green" />}
            onOk={() => props.onLevelEnd()}
        >
            <div className="center-text intro-text p-5">
                <div>Nous sommes en été :</div>
                <div>quels éléments permettent d'optimiser le confort des occupants ?</div>
            </div>
        </SwipableCardStack>
    </div>
</LevelIntroScene>


export const WinterLevelIntroScene = (props: GameLevelProps) => <LevelIntroScene
    background={<WinterLevelIntroBackground />}
    {...props}
>
    <div className="w-100">
        <SwipableCardStack
            notOkIcon={<StartIcon color="Yellow" />}
            onNotOk={() => props.onLevelEnd()}
        >
            <div className="center-text intro-text p-5">
                <div>Nous sommes en hiver :</div>
                <div>quels éléments permettent d'optimiser le confort des occupants ?</div>
            </div>
        </SwipableCardStack>
    </div>
</LevelIntroScene>

export const DomTomLevel1IntroScene = (props: GameLevelProps) => <LevelIntroScene
    background={<DomTomLevelIntroBackground />} {...props}
>
    <div className="w-100">
        <SwipableCardStack
            okIcon={<StartIcon color="Green" />}
            onOk={() => props.onLevelEnd()}
        >
            <div className="center-text intro-text p-5">
                <div>En climat tropical, quels éléments permettent de réduire les apports de chaleur dans le bâtiment ?</div>
            </div>
        </SwipableCardStack>
    </div>
</LevelIntroScene>

export const DomTomLevel2IntroScene = (props: GameLevelProps) => <LevelIntroScene
    background={<DomTomLevelIntroBackground />} {...props}
>
    <div className="w-100">
        <SwipableCardStack
            okIcon={<StartIcon color="Green" />}
            onOk={() => props.onLevelEnd()}
        >
            <div className="center-text intro-text p-5">
                <div>Nous cherchons maintenant à identifier ce qui est favorable à la ventilation naturelle et au rafraîchissement passif des bâtiments</div>
            </div>
        </SwipableCardStack>
    </div>
</LevelIntroScene>

const SummerLevelIntroBackground = () => <img src={SummerBackgroundPng} alt="summer intro background" />
const WinterLevelIntroBackground = () => <img src={WinterBackgroundPng} alt="winter intro background" />
const DomTomLevelIntroBackground = () => <div className='domtom-background' />

type LevelIntroSceneProps = {
    readonly background: JSX.Element;
    readonly children: JSX.Element;
} & GameLevelProps;

const LevelIntroScene = (props: LevelIntroSceneProps) => <div className="d-flex flex-column h-100 w-100 align-items-center justify-content-center">
    <div className="intro-background">
        {props.background}
    </div>

    <div className="intro-foreground w-100">
        {props.children}
    </div>
</div>