import { clamp } from "../Services/Helpers";

export enum BaseGameLevel {
    HomeScreen = 0,
    Tutorial = 1,

    SummerLevelIntro = 2,
    SummerLevel = 3,
    SummerLevelResults = 4,
    SummerLevelExplanations = 5,

    WinterLevelIntro = 6,
    WinterLevel = 7,
    WinterLevelResults = 8,
    WinterLevelExplanations = 9,

    EndScreen = 10,
}

export function nextBaseStep(step: BaseGameLevel, summerPlayed: boolean, winterPlayed: boolean): BaseGameLevel {

    if (step === BaseGameLevel.SummerLevelExplanations) {

        if (winterPlayed) {
            return BaseGameLevel.EndScreen;
        }
        else {
            return BaseGameLevel.WinterLevelIntro;
        }
    }
    else if (step === BaseGameLevel.WinterLevelExplanations) {

        if (summerPlayed) {
            return BaseGameLevel.EndScreen;
        }
        else {
            return BaseGameLevel.SummerLevelIntro;
        }
    }
    else {
        return step + 1;
    }

}


export enum DomTomGameLevel {
    HomeScreen = 0,
    Tutorial = 1,

    Level1Intro = 2,
    Level1 = 3,
    Level1Results = 4,
    Level1Explanations = 5,

    Level2Intro = 6,
    Level2 = 7,
    Level2Results = 8,
    Level2Explanations = 9,
    
    EndScreen = 10,
}

export function nextDomTomStep(step: DomTomGameLevel): DomTomGameLevel {
    return step + 1;
}

export interface GameLevelProps {
    readonly onLevelEnd: () => void;
}

export type Answer = "Ok"|"NotOk";

export type TemperatureDirection = "ColdToHot"|"HotToCold"|"HotToComfort";
type TemperatureFunction = (stats: LevelStats) => number;

export interface Question {
    readonly index: number;
    readonly content: JSX.Element;
    readonly correctAnswer: Answer;
    readonly explanation: string;
}


export type LevelStats = {
    readonly correct: number,
    readonly answered: number,
    readonly total: number
}

const tempSlope = 8;
const correctWeight = 1.0;
const incorrectWeight = 1.5;

const hotToColdTemperature: TemperatureFunction = (stats: LevelStats) => {

    const base: number = 80.0;
    const comfort: number = 50.0;
    const veryHot: number = 100.0;

    const slope = -tempSlope;

    const { correct, answered } = stats;
    const incorrect = answered - correct;

    const result = Math.round(clamp(base + slope * (correct * correctWeight - incorrect * incorrectWeight), comfort, veryHot));
    return result;
}

const coldToHotTemperature: TemperatureFunction = (stats: LevelStats) => {
    
    const base: number = 20.0;
    const comfort: number = 50.0;
    const VeryCold: number = 0.0;

    const slope = tempSlope;

    const { correct, answered } = stats;
    const incorrect = answered - correct;

    const result = Math.round(clamp(base + slope * (correct * correctWeight - incorrect * incorrectWeight), VeryCold, comfort));
    return result;
}

const hotToConfortTemperature: TemperatureFunction = (stats: LevelStats) => {

    const base: number = 60.0;
    const comfort: number = 0.0;
    const veryHot: number = 100.0;

    const slope = - 2 * tempSlope;

    const { correct, answered } = stats;
    const incorrect = answered - correct;

    const result = Math.round(clamp(base + slope * (correct * correctWeight - incorrect * incorrectWeight), comfort, veryHot));
    return result;
}

export const getTemperature = (stats: LevelStats, direction: TemperatureDirection): number => {
    const tempFunc = direction === "ColdToHot" ? coldToHotTemperature :
            direction === "HotToCold" ? hotToColdTemperature :
            hotToConfortTemperature;

    return tempFunc(stats);
}