import './../Styles/Stepper.scss';

export interface StepperProps {
    readonly step: number;
    readonly stepCount: number;
}

export const Stepper = ({step, stepCount}: StepperProps) => <div className={`stepper stepper-${stepCount} d-flex flex-row center-items justify-content-between`}>
    {Array.from(Array(stepCount).keys()).map(i => <div key={`step-${i}`} className={`stepper-dot stepper-dot-${i < step ? "active" : "inactive"} `}>
        <svg viewBox="0 0 10 10">
            <circle cx="5" cy="5" r="5"/>
        </svg>
    </div>)}
</div>