import SummerLevelQuestion1Jpg from '../Images/SummerLevel/Photo 1 - BR - murs pierre.jpg';
import SummerLevelQuestion2Jpg from '../Images/SummerLevel/Photo 2 - BR - point d\'eau.jpg';
import SummerLevelQuestion3Jpg from '../Images/SummerLevel/Photo 3 - BR - toit végétalisé.jpg';
import SummerLevelQuestion4Jpg from '../Images/SummerLevel/Photo 4 - BR - balcons les uns sous les autres.jpg';
import SummerLevelQuestion5Jpg from '../Images/SummerLevel/Photo 5 - MR - feuillages.jpg';
import SummerLevelQuestion6Jpg from '../Images/SummerLevel/Photo 6 - MR - inertie thermique.jpg';
import SummerLevelQuestion7Jpg from '../Images/SummerLevel/Photo 7 - MR - plan mal orienté.jpg';
import SummerLevelQuestion8Jpg from '../Images/SummerLevel/Photo 8 - BR - plan bien orienté.png';
import SummerLevelQuestion9Jpg from '../Images/SummerLevel/Photo 9 - BR - ouverture sud et feuilles caduques.jpg';
import SummerLevelQuestion10Jpg from '../Images/SummerLevel/Photo 10 - MR - ouverture sud chambre.png';
import SummerLevelQuestion11Jpg from '../Images/SummerLevel/Photo 11 - BR - ombrelle.jpg';
import SummerLevelQuestion12Jpg from '../Images/SummerLevel/Photo 12 - BR - pergola.jpg';

import WinterLevelQuestion1Jpg from '../Images/WinterLevel/Photo 1 - BR - ouverture Nord.png';
import WinterLevelQuestion2Jpg from '../Images/WinterLevel/Photo 2 - BR - feuillage persistant nord.png';
import WinterLevelQuestion3Jpg from '../Images/WinterLevel/Photo 3 - BR - bonne orientation plan.png';
import WinterLevelQuestion4Jpg from '../Images/WinterLevel/Photo 4 - MR - volets fermés.png';
import WinterLevelQuestion5Jpg from '../Images/WinterLevel/Photo 5 - BR - inertie thermique.jpg';
import WinterLevelQuestion6Jpg from '../Images/WinterLevel/Photo 6 - BR - mode de vie.png';
import WinterLevelQuestion7Jpg from '../Images/WinterLevel/Photo 7 - BR - ouverture est.png';
import WinterLevelQuestion8Jpg from '../Images/WinterLevel/Photo 8 - MR - verrière au plafond.jpg';
import WinterLevelQuestion9Jpg from '../Images/WinterLevel/Photo 9 - MR - Espace tampons.jpg';
import WinterLevelQuestion10Jpg from '../Images/WinterLevel/Photo 10 - BR - balcons les uns sous les autres.jpg';
import WinterLevelQuestion11Jpg from '../Images/WinterLevel/Photo 11 - MR - vents violents.png';
import WinterLevelQuestion12Jpg from '../Images/WinterLevel/Photo 12 - MR - plan mal orienté.jpg';

import DomTom1LevelQuestion1Png from '../Images/DomTom1/Photo 1 - BR - Protections.png';
import DomTom1LevelQuestion2Png from '../Images/DomTom1/Photo 2 - MR - Façade vitrée.png';
import DomTom1LevelQuestion3Png from '../Images/DomTom1/Photo 3 - MR - Couleur sombre.png';
import DomTom1LevelQuestion4Png from '../Images/DomTom1/Photo 4 - BR - Protection et végétalisation.png';
import DomTom1LevelQuestion5Png from '../Images/DomTom1/Photo 5 - MR - Ensoleillement.png';
import DomTom1LevelQuestion6Png from '../Images/DomTom1/Photo 6 - BR - Store banne.png';
import DomTom1LevelQuestion7Png from '../Images/DomTom1/Photo 7 - MR - Trappe désenfumage.png';
import DomTom1LevelQuestion8Png from '../Images/DomTom1/Photo 8 - MR - Mauvaise protection.png';
import DomTom1LevelQuestion9Png from '../Images/DomTom1/Photo 9 - BR - Bonne protection.png';
import DomTom1LevelQuestion10Png from '../Images/DomTom1/Photo 10 - BR - Coursives.png';
import DomTom1LevelQuestion11Png from '../Images/DomTom1/Photo 11 - MR - Sans protection.png';
import DomTom1LevelQuestion12Png from '../Images/DomTom1/Photo 12 - BR - Façade protégée.png';

import DomTom2LevelQuestion1Png from '../Images/DomTom2/Photo 1 - BR - Jalousies.png';
import DomTom2LevelQuestion2Png from '../Images/DomTom2/Photo 2 - BR - Ilot intérieur.png';
import DomTom2LevelQuestion3Png from '../Images/DomTom2/Photo 3 - MR - Béton.png';
import DomTom2LevelQuestion4Png from '../Images/DomTom2/Photo 4 - BR - Maison créole.png';
import DomTom2LevelQuestion5Png from "../Images/DomTom2/Photo 5 - BR - Brasseurs d'air.png";
import DomTom2LevelQuestion6Png from '../Images/DomTom2/Photo 6 - BR - Plan 1.png';
import DomTom2LevelQuestion7Png from '../Images/DomTom2/Photo 7 - BR - Jalousies et cour intérieure.png';
import DomTom2LevelQuestion8Png from '../Images/DomTom2/Photo 8 - MR - Absence jalousie.png';
import DomTom2LevelQuestion9Png from '../Images/DomTom2/Photo 9 - BR - Végétalisation.png';
import DomTom2LevelQuestion10Png from '../Images/DomTom2/Photo 10 - MR - Plan 2.png';
import DomTom2LevelQuestion11Png from '../Images/DomTom2/Photo 11 - BR - Ventilation traversante.png';
import DomTom2LevelQuestion12Png from '../Images/DomTom2/Photo 12 - BR - Porosité.png';


module Images {

    type ImgProps = {
        readonly className?: string;
    }

    export module SummerLevel {
        export const Question1 = (props: ImgProps) => <img src={SummerLevelQuestion1Jpg} alt="SummerLevelQuestion1" {...props} />
        export const Question2 = (props: ImgProps) => <img src={SummerLevelQuestion2Jpg} alt="SummerLevelQuestion2" {...props} />
        export const Question3 = (props: ImgProps) => <img src={SummerLevelQuestion3Jpg} alt="SummerLevelQuestion3" {...props} />
        export const Question4 = (props: ImgProps) => <img src={SummerLevelQuestion4Jpg} alt="SummerLevelQuestion4" {...props} />
        export const Question5 = (props: ImgProps) => <img src={SummerLevelQuestion5Jpg} alt="SummerLevelQuestion5" {...props} />
        export const Question6 = (props: ImgProps) => <img src={SummerLevelQuestion6Jpg} alt="SummerLevelQuestion6" {...props} />
        export const Question7 = (props: ImgProps) => <img src={SummerLevelQuestion7Jpg} alt="SummerLevelQuestion7" {...props} />
        export const Question8 = (props: ImgProps) => <img src={SummerLevelQuestion8Jpg} alt="SummerLevelQuestion8" {...props} />
        export const Question9 = (props: ImgProps) => <img src={SummerLevelQuestion9Jpg} alt="SummerLevelQuestion9" {...props} />
        export const Question10 = (props: ImgProps) => <img src={SummerLevelQuestion10Jpg} alt="SummerLevelQuestion10" {...props} />
        export const Question11 = (props: ImgProps) => <img src={SummerLevelQuestion11Jpg} alt="SummerLevelQuestion11" {...props} />
        export const Question12 = (props: ImgProps) => <img src={SummerLevelQuestion12Jpg} alt="SummerLevelQuestion12" {...props} />
    }

    export module WinterLevel {
        export const Question1 = (props: ImgProps) => <img src={WinterLevelQuestion1Jpg} alt="WinterLevelQuestion1" {...props} />
        export const Question2 = (props: ImgProps) => <img src={WinterLevelQuestion2Jpg} alt="WinterLevelQuestion2" {...props} />
        export const Question3 = (props: ImgProps) => <img src={WinterLevelQuestion3Jpg} alt="WinterLevelQuestion3" {...props} />
        export const Question4 = (props: ImgProps) => <img src={WinterLevelQuestion4Jpg} alt="WinterLevelQuestion4" {...props} />
        export const Question5 = (props: ImgProps) => <img src={WinterLevelQuestion5Jpg} alt="WinterLevelQuestion5" {...props} />
        export const Question6 = (props: ImgProps) => <img src={WinterLevelQuestion6Jpg} alt="WinterLevelQuestion6" {...props} />
        export const Question7 = (props: ImgProps) => <img src={WinterLevelQuestion7Jpg} alt="WinterLevelQuestion7" {...props} />
        export const Question8 = (props: ImgProps) => <img src={WinterLevelQuestion8Jpg} alt="WinterLevelQuestion8" {...props} />
        export const Question9 = (props: ImgProps) => <img src={WinterLevelQuestion9Jpg} alt="WinterLevelQuestion9" {...props} />
        export const Question10 = (props: ImgProps) => <img src={WinterLevelQuestion10Jpg} alt="WinterLevelQuestion10" {...props} />
        export const Question11 = (props: ImgProps) => <img src={WinterLevelQuestion11Jpg} alt="WinterLevelQuestion11" {...props} />
        export const Question12 = (props: ImgProps) => <img src={WinterLevelQuestion12Jpg} alt="WinterLevelQuestion12" {...props} />
    }

    export module DomTom1Level {
        export const Question1 = (props: ImgProps) => <img src={DomTom1LevelQuestion1Png} alt="DomTom1LevelQuestion1" {...props} />
        export const Question2 = (props: ImgProps) => <img src={DomTom1LevelQuestion2Png} alt="DomTom1LevelQuestion2" {...props} />
        export const Question3 = (props: ImgProps) => <img src={DomTom1LevelQuestion3Png} alt="DomTom1LevelQuestion3" {...props} />
        export const Question4 = (props: ImgProps) => <img src={DomTom1LevelQuestion4Png} alt="DomTom1LevelQuestion4" {...props} />
        export const Question5 = (props: ImgProps) => <img src={DomTom1LevelQuestion5Png} alt="DomTom1LevelQuestion5" {...props} />
        export const Question6 = (props: ImgProps) => <img src={DomTom1LevelQuestion6Png} alt="DomTom1LevelQuestion6" {...props} />
        export const Question7 = (props: ImgProps) => <img src={DomTom1LevelQuestion7Png} alt="DomTom1LevelQuestion7" {...props} />
        export const Question8 = (props: ImgProps) => <img src={DomTom1LevelQuestion8Png} alt="DomTom1LevelQuestion8" {...props} />
        export const Question9 = (props: ImgProps) => <img src={DomTom1LevelQuestion9Png} alt="DomTom1LevelQuestion9" {...props} />
        export const Question10 = (props: ImgProps) => <img src={DomTom1LevelQuestion10Png} alt="DomTom1LevelQuestion10" {...props} />
        export const Question11 = (props: ImgProps) => <img src={DomTom1LevelQuestion11Png} alt="DomTom1LevelQuestion11" {...props} />
        export const Question12 = (props: ImgProps) => <img src={DomTom1LevelQuestion12Png} alt="DomTom1LevelQuestion12" {...props} />
    }

    export module DomTom2Level {
        export const Question1 = (props: ImgProps) => <img src={DomTom2LevelQuestion1Png} alt="DomTom2LevelQuestion1" {...props} />
        export const Question2 = (props: ImgProps) => <img src={DomTom2LevelQuestion2Png} alt="DomTom2LevelQuestion2" {...props} />
        export const Question3 = (props: ImgProps) => <img src={DomTom2LevelQuestion3Png} alt="DomTom2LevelQuestion3" {...props} />
        export const Question4 = (props: ImgProps) => <img src={DomTom2LevelQuestion4Png} alt="DomTom2LevelQuestion4" {...props} />
        export const Question5 = (props: ImgProps) => <img src={DomTom2LevelQuestion5Png} alt="DomTom2LevelQuestion5" {...props} />
        export const Question6 = (props: ImgProps) => <img src={DomTom2LevelQuestion6Png} alt="DomTom2LevelQuestion6" {...props} />
        export const Question7 = (props: ImgProps) => <img src={DomTom2LevelQuestion7Png} alt="DomTom2LevelQuestion7" {...props} />
        export const Question8 = (props: ImgProps) => <img src={DomTom2LevelQuestion8Png} alt="DomTom2LevelQuestion8" {...props} />
        export const Question9 = (props: ImgProps) => <img src={DomTom2LevelQuestion9Png} alt="DomTom2LevelQuestion9" {...props} />
        export const Question10 = (props: ImgProps) => <img src={DomTom2LevelQuestion10Png} alt="DomTom2LevelQuestion10" {...props} />
        export const Question11 = (props: ImgProps) => <img src={DomTom2LevelQuestion11Png} alt="DomTom2LevelQuestion11" {...props} />
        export const Question12 = (props: ImgProps) => <img src={DomTom2LevelQuestion12Png} alt="DomTom2LevelQuestion12" {...props} />
    }
}

export default Images;