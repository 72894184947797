import { Answer, GameLevelProps, Question } from '../Game/GameLogic';
import { zip } from '../Services/Helpers';
import { ColdIcon, ContinueIcon, HotIcon, MagnifyingGlassIcon } from './Icons';

import './../Styles/Explanations.scss';
import { useState } from 'react';
import Popup from './Popup';
import { range } from '../Services/Helpers';

export type LevelExplanationsProps = {
    readonly questions: Question[];
    readonly answers: (Answer|undefined)[];
} & GameLevelProps;


const explanationText = "Zoom sur chaque image pour avoir plus de détails";

export const SummerLevelExplanationsScene = (props: LevelExplanationsProps) => <LevelExplanationsScene
    text={explanationText}
    okElementsText="Augmente le confort"
    okElementsIcon={<ColdIcon color="Green" />}
    notOkElementsText="Dégrade le confort"
    notOkElementsIcon={<HotIcon color="Yellow" />}
    {...props}
/>


export const WinterLevelExplanationsScene = (props: LevelExplanationsProps) => <LevelExplanationsScene
    text={explanationText}
    okElementsText="Augmente le confort"
    okElementsIcon={<HotIcon color="Yellow" />}
    notOkElementsText="Dégrade le confort"
    notOkElementsIcon={<ColdIcon color="Green" />}
    {...props}
/>

export const DomTomLevel1ExplanationScene = (props: LevelExplanationsProps) => <LevelExplanationsScene
    text={explanationText}
    okElementsText="Augmente le confort"
    okElementsIcon={<ColdIcon color="Green" />}
    notOkElementsText="Dégrade le confort"
    notOkElementsIcon={<HotIcon color="Yellow" />}
    {...props}
/>

export const DomTomLevel2ExplanationScene = (props: LevelExplanationsProps) => <LevelExplanationsScene
    text={explanationText}
    okElementsText="Augmente le confort"
    okElementsIcon={<ColdIcon color="Green" />}
    notOkElementsText="Dégrade le confort"
    notOkElementsIcon={<HotIcon color="Yellow" />}
    {...props}
/>



type PrivateLevelExplanationProps = {
    readonly text: string;
    readonly okElementsText: string;
    readonly okElementsIcon: JSX.Element;
    readonly notOkElementsText: string;
    readonly notOkElementsIcon: JSX.Element;
} & LevelExplanationsProps;

const LevelExplanationsScene = (props: PrivateLevelExplanationProps) => {
    const qa = zip(props.questions, props.answers)

    const [displayed, setDisplayed] = useState<number>();
    const popupDisplayed = displayed !== undefined;

    const okElements = qa.filter(([question]) => question.correctAnswer === "Ok");
    const notOkElements = qa.filter(([question]) => question.correctAnswer === "NotOk");

    const onClickFunc = (idx: number) => {
        return () => setDisplayed(idx);
    }

    return <div className="explanations d-flex flex-column h-100 w-100 align-items-center justify-content-top">

        <div className="mt-1 mb-3 d-flex w-100 miniatures-main-container justify-content-evenly">
            <MiniatureContainer
                icon={props.okElementsIcon}
                description={props.okElementsText}
                elements={okElements}
                onClickFunc={onClickFunc}
            />
            <MiniatureContainer
                icon={props.notOkElementsIcon}
                description={props.notOkElementsText}
                elements={notOkElements}
                onClickFunc={onClickFunc}
            />
        </div>

        <div className="explanations-footer mt-4 w-100 d-flex flex-row justify-content-between align-items-center mb-4 px-4">

            <div className="explanations-placeholder"></div>

            <div className="zoom-explanations d-flex flex-column align-items-center">
                <div className="zoom-explanations-icon mb-3">
                    <MagnifyingGlassIcon color="LightGreen" />
                </div>
                <div className="zoom-explanations-text text-center">
                    {explanationText}
                </div>
            </div>

            <div className="icon-btn" onClick={e => props.onLevelEnd()}>
                <ContinueIcon color="DarkGreen" />
            </div>
        </div>

        <Popup displayed={popupDisplayed} onContinueBtnClicked={() => setDisplayed(undefined)}>
            <div className="h-75 d-flex flex-column align-items-center justify-content-center">
                <div className="text-center mt-4">
                    {popupDisplayed ? props.questions[displayed].explanation : "NOTHING TO DISPLAY"}
                </div>
                <div className="text-center w-75 mt-3 flex-grow-1 center-child p-3 explanation-popup-content">
                    {popupDisplayed ? props.questions[displayed].content : "NOTHING TO DISPLAY"}
                </div>
            </div>
        </Popup>
    </div>;
}

type MiniatureContainerProps = {
    readonly icon: JSX.Element;
    readonly description: string;
    readonly elements: ([Question, Answer|undefined])[];
    readonly onClickFunc: (index: number) => Function;
}


const MiniatureContainer = (props: MiniatureContainerProps) => <div className="miniature-container-col">
    
    <div className="w-100 d-flex flew-row justify-content-center my-3">
        <div className="miniature-col-icon">
            {props.icon}
        </div>
    </div>

    <div className="text-center w-100 explanation-description">{props.description}</div>
    <div className="mb-2 mt-1 px-4 w-100 miniatures-container">
        <div className="row">
            {props.elements.map(([{ index, content }]) => {
                
                const onClick = props.onClickFunc(index);
                
                return <Miniature
                    key={`miniature-${index}`}
                    onClick={onClick}
                    >
                        {content}
                </Miniature>
            })}
            {range(12 - props.elements.length).map(i => <MiniaturePlaceHolder
                key={`placeholder-${i}`}
            ></MiniaturePlaceHolder>)}
        </div>
    </div>
</div>


type MiniatureProps = {
    readonly onClick: Function;
    readonly children: JSX.Element;
}

const Miniature = (props: MiniatureProps) => {

    const { onClick, children } = props;

    return <div
        className="col my-2 px-2"
        onClick={() => onClick()}
    >
        <div className="explanation-miniature">
            {children}
        </div>
    </div>
}

const MiniaturePlaceHolder = () => <div className="col px-2">
    <div className="miniature-placeholder">
    </div>
</div>