import { GameLevelProps } from "../Game/GameLogic";

import './../Styles/EndScreen.scss';
import { ClapIcon, FinishIcon, StartIcon } from "./Icons";
import SwipableCardStack from "./SwipableCardStack";

type EndScreenProps = {
    readonly onRestart: () => void;
} & GameLevelProps;

const EndScreen = (props: EndScreenProps) => <div className="endscreen w-100 h-100">
    <div className="d-flex flex-column w-100 h-100 align-items-center justify-content-evenly">
        
        <div className="clap-icon-container">
            <ClapIcon color="Yellow" className="w-100" />
        </div>
        <SwipableCardStack
            onNotOk={props.onLevelEnd}
            notOkIcon={<StartIcon color="Green" />}

            onOk={props.onRestart}
            okIcon={<FinishIcon color="DarkGreen" />}
        >
            <div className="endscreen-text text-center p-4">
                <div className="header-text">Bravo !</div>
                <div className="subtitle-text">Tu as terminé le jeu !</div>
            </div>
        </SwipableCardStack>

        <div className="endscreen-placeholder"></div>
    </div>
</div>;

export default EndScreen;